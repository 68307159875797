
.form_title{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1px;
    font-weight: bolder;
    color: black !important;
}
.custom-table {
    border: 1px solid black !important;
  }
.background-color {
    background-color:black;
    color: white;
    text-align: center;
    /* margin-top: 1%; */
}

.background-color h6 {
    font-size: 20px;
}
.button{
    background-color:midnightblue;
    color: white;
}

.title{
    border: 2px solid black;
    border-top: 2px solid black;
    margin-top: 1%;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;
    color: black !important;
}

.body {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    font-size: 15px;
    padding-top: 2%;
    padding-bottom: 2%;
    color: black !important;
    height: 150px;
}

.text-align{
    text-align: center;
    margin-top: 2%;
    font-size: 17px;
}

.row-border{
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    width: 100%;
    height: 850px;
    padding-top: 0.7%;
}

.col-height{
    height: 100%;
    padding-top: 6%;
    font-size: 15px;
}

.col1-border{
    margin-left: 1%;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;

}
.col2-border{
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;

}
.col3-border{
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;

}

.padding-top{
    padding-top: 2%;
}

.margin-top{
    margin-top: 3%;
}

.font-size{
    font-size: 16px;
}

.border-top{
    border-top: 2px solid black;
}
.total{
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    margin-left: 0.01%;
    margin-right: 0.01%;
    font-size: 16px;
}

.doctor-signature{
    font-size: 15px;
    font-weight: bold;
    border-top: 2px solid black;
    margin-top: 40%;
    text-align: center;
    margin-right: 3%;
}

@media print {
    footer {
        position: fixed;
        /* left: 0; */
        /* bottom: 0; */
        /* width: 100%; */
        /* font-size: 25px; */
        /* background-color: slategrey; */
        /* -webkit-print-color-adjust: exact; */
        /* color: white; */
    }
    .background-color {
        background-color:slategrey;
        -webkit-print-color-adjust: exact;
        color: white;
        text-align: center;
        margin-top: 1%;
    }
}
/* .mfooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color:black;
    color: white;
    text-align: center;
 } */

/* .border-left{
    border-left: 2px solid red;
} */

/* PRESCRIPTION REPORT  */
  .header {
    color: #333;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  .logo {
    flex: 2;
  }
  .logo img {
    width: 120px;
    height: 120px;
    float: left;
  }
  .credentials {
    /* flex: 1;
    justify-content: right; */
    align-content: flex-end;
  }
  .credentials h4 {
    line-height: 1em;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
  }
  .credentials p {
    margin: 0 0 0 0;
    padding: 3px 0px;
  }
  .credentials small {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding-right: 80px;
  }

  Table{
    border-color: black;
  }