#main_body_invoicepr {
    font-family: SourceSansPro;
    color: #000;
    height: 29.7cm; 
}

#qrcode_invpr {
    text-align: right;
    margin-bottom: -90px;
    margin-top: 10px;
    margin-right: 10px;
}


#address_invpr {
    font-weight: 500;
    margin-bottom: 30px;
}

#estimateinvpr {
    margin-top: -30px;
    text-align: left;
    font-size: 18px;
}

#invoice_ndt_invpr {
    padding-top: 10px;
}

#trans_invpr {
    text-align: right;
    margin-top: -40px;
    padding-right: 100px;
}

#phoneno_invpr {
    text-align: right;
    margin-top: -40px;
    padding-right: 100px;
}

#left_heading_tr_invpr {
    float: right;
    text-align: left;
}

#left__value_tr_invpr {
    text-align: right;
}

#partynameinvpr {
    height: 40px;
}

#partynameinvpr_party {
    padding-top: 10px;
    vertical-align: center;
    width: 100px;
    height: 40px;
    float: left;
    /* border: 2px solid red; */
}

#partynameinvpr_name {
    padding-top: 10px;
    vertical-align: center;
    width: 500px;
    height: 40px;
    float: left;
    font-weight: bold;
    font-size: 18px;
    /* border: 2px solid red; */
}

#main_table_invoicepr th {
    border-top: 2px solid black;
    border-bottom: 2px solid black;
    padding: 8px;
    background-color: #AAAAAA;
}

#main_table_invoicepr td {
    border-bottom: 2px solid black;
    padding: 8px;
}

#main_table_invoicepr {
    padding: 8px;
    width: 100%;
    border-collapse: collapse;
}

#main_table_invoicepr tfoot {
    font-weight: bold;
}

#after_table_invprt {
    height: 200px;
    /* background-color: aqua; */
    padding-top: 30px;
}

#after_table_invprt_left {
    position: relative;
    height: 200px;
    width: 50%;
    float: left;
    /* background-color: blue; */
    text-align: left;
}

#rs_in_words_invprt {
    padding-top: 5px;
}

#rec_bal_invprt {
    padding-left: 20px;
    padding-top: 8px;
}

#underline_invprt {
    margin-top: -30px;
    padding-left: 10px;
}

#amount_rcv_cur_invprt {
    width: 450px;
    /* height: 50px; */
    margin-top: 10px;
    /* background-color: coral; */
}

#amount_rcv_cur_rcv_invprt {
    width: 180px;
    /* background-color: rgb(151, 122, 7); */
    float: left;
    text-align: center;
    left: 0px;
}


/* #amount_rcv_cur_cur_invprt{
    width: 180px;
    background-color: cyan;
    float: left;
    text-align: center;
    margin-left : 70px;
} */

#after_table_invprt_right {
    height: 250px;
    width: 50%;
    float: right;
    /* background-color: brown; */
    text-align: right;
}

#dic_val_invprt,
#tot_val_invprt,
#prvbal_val_invprt,
#netbal_val_invprt,
#frtchar_val_invprt {
    /* position: inherit; */
    width: 400px;
    height: 30px;
    /* background-color: darkgoldenrod; */
    float: right;
    margin-top: 0px;
    margin-bottom: -3px;
}

#underline_rightsec_invprt {
    margin-top: 0px;
    margin-bottom: -3px;
    /* background-color: darkslateblue; */
    float: right;
}

#dic_val_invprt_p_u {
    position: absolute;
    text-align: left;
    width: 400px;
    text-align: center;
    margin-top: 70px;
}

#dic_val_dic_invprt_p {
    height: 40px;
}

#dic_val_val_invprt_p {
    height: 40px;
}

#dic_val_dic_invprt,
#tot_val_tot_invprt,
#prvbal_val_pvrbal_invprt,
#netbal_val_netbal_invprt,
#frtchar_val_frtchar_invprt {
    float: left;
    text-align: left;
    left: 0px;
    margin-top: 0px;
    margin-bottom: 9px;
    /* background-color: darkgrey; */
}

#dic_val_val_invprt,
#tot_val_val_invprt,
#prvbal_val_val_invprt,
#netbal_val_val_invprt,
#frtchar_val_val_invprt {
    float: right;
    right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: Arial, sans-serif;
    /* background-color: darkslateblue; */
}

#note_invprt {
    margin-top: -100px;
    float: left;
    left: 0px;
}

#note_detail_invprt {
    margin-top: -80px;
    float: left;
    left: 0px;
    padding-left: 40px;
}

#creby_invprt {
    position: absolute;
    margin-top: -40px;
    margin-bottom: 0px;
    right: 185px;
}

@media print{
    div.divFooter {
      position: fixed ;
      bottom: 0;
    }
  }
  @media screen {
    div.divFooter {
      display: none;
    }
  }