
.k-animation-container{
  z-index: 20003;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  background: red
}
.swal-overlay {
  background-color: rgba(43, 165, 137, 0.45);
}

.chat-launcher {
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  box-shadow: 0 0 6px rgb(33 33 33 / 16%), 0 6px 12px rgb(33 33 33 / 32%);
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  z-index: 12;
  background: #212121;
  color: #fff;
  display: none;
  cursor: pointer;
}

