@media print {
    .page-break { display: block; page-break-before: always; }
  }

#main_div_invprntthrml{
  position: absolute;
  margin-left: 0%;
  margin-top: 0%;
}

#invoice-POS {
  padding-left: 3px;
  padding-right: 3px;
  box-shadow: 0 0 1in -0.25in rgba(0, 0, 0, 0.5);
  /* padding: 2mm; */
  /* margin: 0 auto; */
  width: 3in;
  background: #FFF;
  margin-top: 0%;
}
#invoice-POS ::selection {
  background: #f31544;
  color: #FFF;
}
#invoice-POS ::moz-selection {
  background: #f31544;
  color: #FFF;
}
#invoice-POS h1 {
  font-size: 1.5em;
  color: #000;
}
#invoice-POS h2 {
  font-size: .9em;
  color: #000;
}
#invoice-POS h3 {
  font-size: 1.2em;
  font-weight: 300;
  line-height: 2em;
}
#invoice-POS p {
  font-size: .7em;
  color: #000;
  line-height: 1.6em;
}
#mid p {
  margin-top: 0px;
  position: inherit;
}
#invoice-POS #top, #invoice-POS #bot {
  width: 100%;
  /* Targets all id with 'col-' */
  border-bottom: 1px solid #000;
}
#invoice-POS #top {
  min-height: 100px;
}
#invoice-POS #mid {
  min-height: 40px;
}
#invoice-POS #bot {
  min-height: 50px;
}
#invoice-POS #top .logo {
  height: 60px;
  width: 60px;
  /* background: url(http://michaeltruong.ca/images/logo1.png) no-repeat; */
  background-size: 60px 60px;
}
#invoice-POS .clientlogo {
  float: left;
  height: 60px;
  width: 60px;
  background: url(http://michaeltruong.ca/images/client.jpg) no-repeat;
  background-size: 60px 60px;
  border-radius: 50px;
}
#table_invtherprnt{
  position: inherit;
  width: 94%;
  /* align-items: center; */
  /* margin-right: 3%; */
  /* padding-left: 2px; */
  background-color: white;
}

#table_invtherprnt table{
  position: inherit;
  width: 2.9in;
  color: #000;
  background-color: white;
}

#table_invtherprnt table thead tr th:last-child{
  text-align: right;
}

#table_invtherprnt thead tr{
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  font-size: 12px;
  /* background: #EEE; */
}

#table_invtherprnt tbody tr{
  color: #000;
  font-size: 10px;
  line-height: 2em;
}

#table_invtherprnt tbody #ttl_dis_fr_invprntthrml {
  font-size: 11px;
  border-top: 1px solid black;
}

#table_invtherprnt tbody #dis_invprntthrml {
  /* font-weight: 550; */
  font-size: 11px;
}

#table_invtherprnt tbody #frghtchrg_invprntthrml {
  font-size: 11px;
  border-bottom: 1px solid black;
}

#table_invtherprnt tfoot{
  color: #000;
  line-height: 1.5em;
}

#table_invtherprnt tfoot #netamnt_invprntthrml {
  font-size: 13px;
  /* border-bottom: 1px solid black; */
}
#table_invtherprnt tfoot #netamnt_invprntthrml td:last-child {
  font-size: 11px;
  /* border-bottom: 1px solid black; */
}

#table_invtherprnt tfoot #prvblnc_invprntthrml, #ttlblnc_invprntthrml, #amntrcvd_invprntthrml, #netblnc_invprntthrml {
  font-size: 11px;
  /* border-bottom: 1px solid black; */
}

#table_invtherprnt tfoot:last-child{
  border-bottom: 1px solid #000;
}

/* #table_invtherprnt tbody tr:last-child{
  border-bottom: black;
} */
#invoice-POS #legalcopy {
  text-align: center;
  margin-top: 5mm;
}