#ttl_rec_main{
    /* background-color: darkturquoise; */
    padding-top: 1%;
    padding-left: 3%;

}

#total_rec_amnt{
    padding: 2%;
    height: 100%;
    /* background-color: #806c8c; */
    border: 1px solid #613abb;
    /* border: 3px solid #806c8c; */
    /* text-align: center; */
    /* background-color: #69487c; */
}

#total_rec_amnt:hover{
    background-color: #613abb;
    cursor: pointer;
}

#total_rec_amnt:hover > #total_rec_amnt_p{
    color: white;
}
#total_rec_amnt:hover > #total_rec_amnt_h6{
    color: white;
}

#total_rec_amnt_p{
    /* left: 0px;
    float: left; */

    margin-bottom: -10px;
    margin-top: 0px;
    /* color: white; */
    color: #613abb;
    font-size: 12px;
}

#total_rec_amnt_h6{
    /* text-align: center; */
    padding-left: 30%;
    margin-top: 0px;
    /* color: white; */
    color: #613abb;
    font-weight: bold;
    font-size: 22px;
}

#total_rec_cahrt{
    padding-bottom: 2px;
}



/* For Pay able */


#ttl_pay_main{
    /* background-color: darkturquoise; */
    padding-top: 1%;
    padding-left: 3%;

}

#total_pay_amnt{
    padding: 2%;
    height: 100%;
    /* background-color: #806c8c; */
    border: 3px solid #806c8c; 
    /* text-align: center; */
    /* background-color: darkturquoise; */
}

#total_pay_amnt:hover{
    /* background-color: #806c8c; */
    background-color: #69487c;

    cursor: pointer;
}

#total_pay_amnt:hover > #total_pay_amnt_p{
    color: white;
}
#total_pay_amnt:hover > #total_pay_amnt_h6{
    color: white;
}

#total_pay_amnt_p{
    /* left: 0px;
    float: left; */

    margin-bottom: -10px;
    margin-top: 0px;
    /* color: white; */
    color: purple;
    font-size: 12px;
}

#total_pay_amnt_h6{
    /* text-align: center; */
    padding-left: 30%;
    margin-top: 0px;
    /* color: white; */
    color: purple;
    font-weight: bold;
    font-size: 22px;
}

#total_pay_cahrt{
    padding-bottom: 2px;
}



/* For Bank Balance */


#ttl_bbal_main{
    /* background-color: darkturquoise; */
    padding-top: 1%;
    padding-left: 3%;

}

#total_bbal_amnt{
    padding: 2%;
    height: 100%;
    /* background-color: #806c8c; */
    border: 3px solid #806c8c;
    /* text-align: center; */
    /* background-color: darkturquoise; */
}

#total_bbal_amnt:hover{
    /* background-color: #806c8c; */
    background-color: #69487c;

    cursor: pointer;
}

#total_bbal_amnt:hover > #total_bbal_amnt_p{
    color: white;
}
#total_bbal_amnt:hover > #total_bbal_amnt_h6{
    color: white;
}

#total_bbal_amnt_p{
    /* left: 0px;
    float: left; */

    margin-bottom: -10px;
    margin-top: 0px;
    /* color: white; */
    color: purple;
    font-size: 12px;
}

#total_bbal_amnt_h6{
    /* text-align: center; */
    padding-left: 30%;
    margin-top: 0px;
    /* color: white; */
    color: purple;
    font-weight: bold;
    font-size: 22px;
}

#total_bbal_cahrt{
    padding-bottom: 2px;
}

