.comptitle{
    
    font-weight: bolder;
    text-align: center;
    text-transform: capitalize;
    color: black;
}
.compsubtitles{
    font-weight: bold;
    text-align: center;
    text-transform: capitalize;
    color: black;
}
.rptTitle{
    color: white;
    background-color: black;
    text-align: center;
    font-weight: bold;
    padding: 10px;
  
}
.custome_border {
    border : 6px  double black !important;
    margin: 2%;
    justify-content: center;
  
    
}


@media print{
    body {
        background-color: white;
      }
    
}