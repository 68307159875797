
.form_title1{
    text-align: center;
    font-size: 1.5rem;
    margin-top: 1px;
}

.background-color1 {
    background-color:slategrey;
    color: white;
    text-align: center;
    /* margin-top: 1%; */
}

.background-color1 h6 {
    font-size: 20px;
}
.button1{
    background-color:midnightblue;
    color: white;
}

.title1{
    border: 2px solid black;
    border-top: 2px solid black;
    margin-top: 1%;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;
}

.body1 {
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    font-size: 15px;
    padding-top: 2%;
    padding-bottom: 2%;
}

.text-align1{
    text-align: center;
    margin-top: 2%;
    font-size: 17px;
}

.row-border1{
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    border-top: 2px solid black;
    padding-top: 0.7%;
}

.col-height1{
    height: 100%;
    padding-top: 6%;
    font-size: 15px;
}

.col1-border1{
    margin-left: 1%;
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;

}
.col2-border1{
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;

}
.col3-border1{
    font-weight: bold;
    text-align: center;
    font-size: 1rem;
    width: 100%;

}

.padding-top1{
    padding-top: 2%;
}

.margin-top1{
    margin-top: 3%;
}

.font-size1{
    font-size: 16px;
}

.border-top1{
    border-top: 2px solid black;
}
.total1{
    border-left: 2px solid black;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    margin-left: 0.01%;
    margin-right: 0.01%;
    font-size: 16px;
}

.doctor-signature1{
    font-size: 15px;
    font-weight: bold;
    border-top: 2px solid black;
    margin-top: 40%;
    text-align: center;
    margin-right: 3%;
}

@media print {
    footer {
        position: fixed;
        /* left: 0; */
        /* bottom: 0; */
        /* width: 100%; */
        /* font-size: 25px; */
        /* background-color: slategrey; */
        /* -webkit-print-color-adjust: exact; */
        /* color: white; */
    }
    .background-color1 {
        background-color:slategrey;
        -webkit-print-color-adjust: exact;
        color: white;
        text-align: center;
        margin-top: 1%;
    }
}
/* .mfooter {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color:black;
    color: white;
    text-align: center;
 } */

/* .border-left{
    border-left: 2px solid red;
} */

/* PRESCRIPTION REPORT  */
  .header1 {
    color: #333;
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
  }
  .logo1 {
    flex: 2;
  }
  .logo1 img {
    width: 120px;
    height: 120px;
    float: left;
  }
  .credentials1 {
    /* flex: 1;
    justify-content: right; */
    align-content: flex-end;
  }
  .credentials1 h4 {
    line-height: 1em;
    letter-spacing: 1px;
    font-weight: 700;
    margin: 0px;
    padding: 0px;
  }
  .credentials1 p {
    margin: 0 0 0 0;
    padding: 3px 0px;
  }
  .credentials1 small {
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    padding-right: 80px;
  }